import React, { useMemo } from 'react';
import type { FormikHelpers } from 'formik';

import { deburr, isBoolean, isLocalEnvironment, orderBy } from '../../../utils';

import Select from '../Select';
import Text from '../Text';
import DatePicker from '../DatePicker';
import Autocomplete from '../Autocomplete';
import Switch from '../Switch';
import MultiSelect from '../MultiSelect';
import { getMonthsOptions } from './ValidationFactory';
import type { Field } from '../../../types';

import { useLocale } from '../../Intl';
import type { SearchInputProps } from '../../SearchInput';
import { SearchInput } from '../../SearchInput';
import type { SelectProps } from '../Select/Select';

export type InputFactoryOnChangeFunc<TValue = any> = (args: {
  event?: React.ChangeEvent<HTMLInputElement>;
  name: string;
  value: TValue;
  setFieldValue: FormikHelpers<unknown>['setFieldValue'];
}) => void;

export type InputFieldFactoryProps = {
  className?: string;
  disabled?: boolean;
  hideErrorText?: boolean;
  onChange?: InputFactoryOnChangeFunc | SelectProps['onChange'] | SearchInputProps['onChange'];
  field: Field;
  minRows?: number;
};

const InputFieldFactory = ({
  field,
  onChange,
  className,
  hideErrorText,
  disabled = false,
  minRows = 3,
}: InputFieldFactoryProps) => {
  const locale = useLocale();
  const variant = field.variant;

  const componentField = useMemo(() => {
    const options = field.options || {};
    let items = field.items || [];
    let type = field.type ?? 'text';

    if (type === 'text' && options?.style === 'select') {
      type = 'select';
    }
    if (type === 'month') {
      items = getMonthsOptions(locale);
    }

    if (type === 'autocomplete' && field.name.includes('country')) {
      const intlRegionFactory = new Intl.DisplayNames(locale.locale.code, { type: 'region' });

      items = items.map(option => {
        try {
          return {
            element: intlRegionFactory.of(option),
            value: option,
          };
        } catch (e) {
          return {
            element: '',
            value: '',
          };
        }
      });

      const filteredItems = items.filter(item => item.value !== '');

      items = orderBy(filteredItems, [o => deburr((o.element ?? '').toLocaleLowerCase())], ['asc']);
    }

    const newField = {
      ...field,
      type,
      items,
    };

    delete newField.variant;
    delete newField.sizeHint;

    if (isBoolean(disabled)) {
      newField.disabled = (newField.disabled ?? false) || disabled;
    }

    return newField;
  }, [disabled, field, locale]);

  switch (componentField.type) {
    case 'multi-select': {
      return (
        <MultiSelect
          className={className}
          onChange={onChange as SelectProps['onChange']}
          field={componentField}
          variant={variant}
        />
      );
    }
    case 'list':
    case 'select': {
      return (
        <Select
          className={className}
          onChange={onChange as SelectProps['onChange']}
          field={componentField}
          variant={variant}
        />
      );
    }
    case 'dayAndMonth':
    case 'date': {
      return (
        <DatePicker
          className={className}
          onChange={onChange as InputFactoryOnChangeFunc}
          field={componentField}
          variant={variant}
          debounceTime={componentField.debounceTime || 500}
        />
      );
    }
    case 'checkbox':
    case 'boolean': {
      return (
        <Switch
          className={className}
          onChange={onChange as InputFactoryOnChangeFunc}
          field={componentField}
          variant={variant}
        />
      );
    }
    case 'month': {
      return (
        <Autocomplete
          className={className}
          onChange={onChange as InputFactoryOnChangeFunc}
          field={componentField}
          variant={variant}
        />
      );
    }
    case 'autocomplete': {
      return (
        <Autocomplete
          className={className}
          onChange={onChange as InputFactoryOnChangeFunc}
          field={componentField}
          variant={variant}
        />
      );
    }
    case 'search': {
      return (
        <SearchInput
          onChange={onChange as SearchInputProps['onChange']}
          placeholder={componentField.label}
          className={className}
          debounceTime={componentField.debounceTime || 500}
          initialSearchQuery={field?.initialValue}
        />
      );
    }
    case 'url':
    case 'tel':
    case 'number':
    case 'email':
    case 'password':
    case 'text': {
      return (
        <Text
          className={className}
          onChange={onChange as InputFactoryOnChangeFunc}
          field={componentField}
          variant={variant}
          hideErrorText={hideErrorText}
        />
      );
    }
    default:
      if (isLocalEnvironment()) {
        //throw new Error('Unsupported type: ' + componentField.type);
      }
      return (
        <Text
          className={className}
          onChange={onChange as InputFactoryOnChangeFunc}
          field={componentField}
          variant={variant}
          hideErrorText={hideErrorText}
        />
      );
  }
};

export default InputFieldFactory;
