import axios from 'axios';

import type { ProductPensionSchemeEnum, User, Owner, EventFlow, Insurer, Employer } from '../../../types';

import { convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload } from '../../../utils';

import type { TableCellHeaderSortOptions } from '../../../components';
import type { EventTypeEnum } from '../../../types/eventType.type';
import { EmployeesListTypeSchemeEnum } from '../../../types/employeesList.type';

type TableFilters = {
  page?: number;
  pageSize?: number;
  searchQuery?: string;
  filters?: {
    status?: string;
    subOwners?: Array<string>;
    productPensionScheme?: ProductPensionSchemeEnum;
    employeesListTypeScheme?: EmployeesListTypeSchemeEnum;
    eventType?: EventTypeEnum;
    employee?: string;
    ibUser?: string;
    ibInsurer?: string;
    ibEmployer?: string;
    eventDateBefore?: string;
    eventDateAfter?: string;
    employerStatus?: 'active' | 'inactive' | 'pending' | 'underConstruction';
  };
  sortBy?: Record<string, TableCellHeaderSortOptions>;
  searchKey?: 'employerName' | 'connectionName' | 'employee';
};

const normalizeUnprocessedTableFilters = ({
  page,
  pageSize,
  searchQuery,
  filters,
  sortBy,
  searchKey = 'employerName',
}: TableFilters) => {
  const { searchQuery: normalizedSearchQuery, ...params } =
    convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload({
      page,
      searchQuery,
      pageSize,
      filters: {
        subOwnerId: filters?.subOwners,
        employerStatus: filters?.employerStatus,
        connectionType: filters?.productPensionScheme,
        employeesListTypeScheme: filters?.employeesListTypeScheme,
      },
      sortBy,
    });

  return {
    ...params,
    [searchKey]: normalizedSearchQuery,
  };
};

const normalizeEventFlowTableFilters = ({
  page,
  pageSize,
  searchQuery,
  filters,
  sortBy,
  searchKey = 'employerName',
}: TableFilters) => {
  const { searchQuery: normalizedSearchQuery, ...params } =
    convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload({
      page,
      searchQuery,
      pageSize,
      filters: {
        status: filters?.status,
        pensionScheme: filters?.productPensionScheme,
        eventType: [filters?.eventType],
        employee: [filters?.employee],
        userId: filters?.ibUser,
        insurerId: filters?.ibInsurer,
        employerId: filters?.ibEmployer,
        eventDate: { before: filters?.eventDateBefore, after: filters?.eventDateAfter },
      },
      sortBy,
    });

  return {
    ...params,
    [searchKey]: normalizedSearchQuery,
  };
};

export type GetDataProcessingCountsParams = {
  userId: User['userId'];
};

const getDataProcessingCounts = async ({ userId }: GetDataProcessingCountsParams) => {
  return axios.get(`/users/${userId}/dashboard/data-processing-counts`);
};

export type GetUnprocessedEventsCountParams = Pick<GetUnprocessedEventsParams, 'userId' | 'filters' | 'searchQuery'>;

const getUnprocessedEventsCount = async ({ userId, searchQuery, filters }: GetUnprocessedEventsCountParams) => {
  return axios.get(`/users/${userId}/dashboard/unprocessed-events-count`, {
    params: {
      ...normalizeUnprocessedTableFilters({
        searchQuery,
        filters,
      }),
    },
  });
};

export type GetUnprocessedEventsParams = {
  userId: User['userId'];
} & TableFilters;

const getUnprocessedEvents = async ({
  userId,
  page,
  pageSize,
  searchQuery,
  filters,
  sortBy,
}: GetUnprocessedEventsParams) => {
  return axios.get(`/users/${userId}/dashboard/unprocessed-events`, {
    params: {
      ...normalizeUnprocessedTableFilters({
        page,
        pageSize,
        searchQuery,
        filters,
        sortBy,
      }),
    },
  });
};

export type GetUnprocessedBatchesParams = {
  userId: User['userId'];
} & TableFilters;

const getUnprocessedBatches = async ({
  userId,
  page,
  pageSize,
  searchQuery,
  filters,
  sortBy,
}: GetUnprocessedEventsParams) => {
  return axios.get(`/users/${userId}/dashboard/unprocessed-batches`, {
    params: {
      ...normalizeUnprocessedTableFilters({
        page,
        pageSize,
        searchQuery,
        filters,
        sortBy,
        searchKey: 'connectionName',
      }),
    },
  });
};

export type GetMissingDetailsParams = {
  userId: User['userId'];
} & TableFilters;

const getMissingDetails = async ({
  userId,
  page,
  pageSize,
  searchQuery,
  filters,
  sortBy,
}: GetUnprocessedEventsParams) => {
  return axios.get(`/users/${userId}/dashboard/missing-details`, {
    params: {
      ...normalizeUnprocessedTableFilters({
        page,
        pageSize,
        searchQuery,
        filters,
        sortBy,
        searchKey: 'connectionName',
      }),
    },
  });
};

export type GetEventFlowDetailsParams = {
  userId: User['userId'];
  ownerId: Owner['ownerId'];
  status: string | null;
} & TableFilters;

const getEventFlowDetails = async ({
  ownerId,
  userId,
  page,
  pageSize,
  searchQuery,
  filters,
  sortBy,
  status,
}: GetEventFlowDetailsParams) => {
  return axios.get(`/users/${userId}/event-flow`, {
    params: {
      ...normalizeEventFlowTableFilters({
        page,
        pageSize,
        searchQuery,
        filters,
        sortBy,
        searchKey: 'employee',
      }),
      status,
    },
  });
};

export type UpdateEventFlowDetailsPayload = {
  eventFlowId: EventFlow['eventId'];
  userId: User['userId'];
  ownerId: Owner['ownerId'];
  connectionId: EventFlow['connectionId'];
  status: EventFlow['status'];
  comment: EventFlow['comment'];
};

const updateEventFlowDetails = async ({
  ownerId,
  eventFlowId,
  connectionId,
  userId,
  status,
  comment,
}: UpdateEventFlowDetailsPayload) => {
  return axios.put(`/users/${userId}/event-flow/${eventFlowId}`, {
    eventFlowId,
    connectionId,
    userId,
    status,
    comment,
  });
};

export type BulkUpdateEventFlowDetailsPayload = {
  ownerId: Owner['ownerId'];
  eventFlowIds: Array<EventFlow['eventId']>;
  userId?: User['userId'];
  status?: EventFlow['status'];
  comment?: EventFlow['comment'];
};

const bulkUpdateEventFlowDetails = async ({
  ownerId,
  eventFlowIds,
  userId,
  status,
  comment,
}: BulkUpdateEventFlowDetailsPayload) => {
  return axios.put(`/users/${userId}/event-flow`, {
    eventFlowIds,
    userId,
    status,
    comment,
  });
};

type DownloadEventFlowRowPayloadFilters = {
  eventFlowIds?: Array<EventFlow['mongoId']>;
  status?: string;
  pensionScheme?: string;
  eventType?: string;
  userId?: User['userId'];
  insurerId?: Insurer['insurerId'];
  employerId?: Employer['employerId'];
  startEventDate?: string;
  endEventDate?: string;
  employee?: string;
  productId?: string;
};

export type DownloadEventFlowRowPayload = {
  userId: User['userId'];
  filters?: DownloadEventFlowRowPayloadFilters;
  selectAll: boolean;
};

const downloadEventFlowRows = async ({ userId, filters, selectAll }: DownloadEventFlowRowPayload) => {
  return axios.post(`/users/${userId}/event-flow/download`, {
    userId,
    filters,
    selectAll,
  });
};

export type GetConnectionErrorsDetailsParams = {
  userId: User['userId'];
  status: string | null;
} & TableFilters;

const getConnectionErrorsDetails = async ({
  userId,
  page,
  pageSize,
  searchQuery,
  filters,
  sortBy,
  status,
}: GetConnectionErrorsDetailsParams) => {
  return axios.get(`/users/${userId}/tasks`, {
    params: {
      ...normalizeUnprocessedTableFilters({
        page,
        pageSize,
        searchQuery,
        filters,
        sortBy,
        searchKey: 'connectionName',
      }),
      status,
    },
  });
};

export type GetBlackAndWhiteListDetailsParams = {
  userId: User['userId'];
} & TableFilters;

const getBlackAndWhiteListDetails = async ({
  userId,
  page,
  pageSize,
  searchQuery,
  filters,
  sortBy,
}: GetBlackAndWhiteListDetailsParams) => {
  return axios.get(`/users/${userId}/connections`, {
    params: {
      ...normalizeUnprocessedTableFilters({
        page,
        pageSize,
        searchQuery,
        filters: {
          employeesListTypeScheme: filters?.employeesListTypeScheme ?? EmployeesListTypeSchemeEnum.All,
        },
        sortBy,
        searchKey: 'connectionName',
      }),
    },
  });
};

export const dashboardService = {
  getDataProcessingCounts,
  getUnprocessedEventsCount,
  getUnprocessedEvents,
  getUnprocessedBatches,
  getMissingDetails,
  getEventFlowDetails,
  updateEventFlowDetails,
  bulkUpdateEventFlowDetails,
  downloadEventFlowRows,
  getConnectionErrorsDetails,
  getBlackAndWhiteListDetails,
};
