import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import type { TableSelectedState } from 'app/store';
import { StoreActions, StoreSelectors, useStoreDispatch, useStoreSelector } from 'app/store';
import type { Employer, User, Insurer } from 'app/types';
import { Uuid, triggerFileDownload } from 'app/utils';
import { useGetEventFlowDetailsQuery, useOwnerGetEmployersQuery, useSnakeBar } from 'app/hooks';
import { useBreadcrumb } from 'app/components';
import { EventFlowDetailsTable } from './components/EventFlowDetailsTable';
import { UNPROCESSED_TABLE_FILTERS_FIELD_NAMES } from '../../Components';
import { EventFlowTableFilters } from './components/EventFlowTableFilters';
import { EventFlowTableActions } from './components/EventFlowTableActions';
import { useEventFlowUsersQuery } from '../../../Users/Hooks';
import { useInsurerGetInsurersQuery } from '../../../../hooks';

import { TranslationKeys } from '../../../../translations';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { useDownloadEventFlow } from '../../../../hooks/api/dashboard/useDownloadEventFlow';

export const EventFlowDetailsPage = () => {
  const EVENT_FLOW_TABLE_FILTERS_FIELD_NAMES = {
    EventType: 'eventType',
    IbUser: 'ibUser',
    IbEmployer: 'ibEmployer',
    EventDateAfter: 'eventDateAfter',
    EventDateBefore: 'eventDateBefore',
  } as const;

  const dispatch = useStoreDispatch();
  const { showErrorSnakeBar, showSuccessSnakeBar } = useSnakeBar();
  const intl = useIntl();

  useBreadcrumb(TranslationKeys.dashboard_subCategory_eventFlow, {
    customPathName: '/dashboard/data-processing/event-flow',
  });
  useBreadcrumb(TranslationKeys.dashboard_subCategory_eventFlow);

  const [searchParams] = useSearchParams();

  const [key, setKey] = React.useState(Uuid.newV4);

  const searchParamsStatus: string | null = React.useMemo(() => {
    return searchParams.has('status') ? searchParams.get('status') : null;
  }, [searchParams]);

  const userId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectUserId(state.AppReducer));

  const ownerId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectOwnerId(state.AppReducer));

  const { searchQuery, page, pageSize, filters, sortBy } = useStoreSelector<TableSelectedState>(state =>
    StoreSelectors.DashboardSelector.selectEventFlowDetails(state.DashboardReducer),
  );
  const {
    page: userPage,
    pageSize: userPageSize,
    searchQuery: userSearchQuery,
  } = useStoreSelector<TableSelectedState>(state => state.UsersReducer);
  const { selectedRows } = useStoreSelector(state =>
    StoreSelectors.DashboardSelector.selectEventFlowDetails(state.DashboardReducer),
  );

  const { data: users } = useEventFlowUsersQuery({
    ownerId,
    page: userPage,
    perPage: userPageSize,
    searchQuery: userSearchQuery,
    internal: true,
  });

  const { data: insurers } = useInsurerGetInsurersQuery({
    variables: { page: 1, pageSize: 999 },
  });

  const { data: employers } = useOwnerGetEmployersQuery({
    variables: { userId, perPage: 1000 },
    options: {
      enabled: !!ownerId,
    },
  });

  const {
    data: eventFlowDetails,
    isLoading: isLoadingEventFlowDetailsQuery,
    isFetching: isFetchingEventFlowDetailsQuery,
    refetch: refetchEventFlow,
  } = useGetEventFlowDetailsQuery({
    variables: {
      ownerId,
      userId,
      page,
      pageSize,
      searchQuery,
      filters,
      sortBy,
      status: filters.status || searchParamsStatus,
    },
    options: {
      enabled: !!ownerId && !!searchParamsStatus,
    },
  });

  const { mutateAsync: downloadEventFlowAsync, isLoading: isDownloadEventFlowLoading } = useDownloadEventFlow();

  const isLoading = isLoadingEventFlowDetailsQuery || isFetchingEventFlowDetailsQuery || isDownloadEventFlowLoading;

  React.useEffect(() => {
    if (searchParamsStatus) {
      dispatch(StoreActions.DashboardAction.eventFlowDetails.applyFilter('status', searchParamsStatus));
    }
  }, [searchParamsStatus, dispatch]);

  useEffect(() => {
    if (!searchParamsStatus && !filters.status) {
      showErrorSnakeBar({
        method: 'searchParamsStatus',
        message: intl.formatMessage({ id: TranslationKeys.eventflow_messages_selectStatus }),
      });
    }
  }, [filters.status, intl, searchParamsStatus, showErrorSnakeBar]);

  const onSortChange = React.useCallback(
    sortBy => {
      dispatch(StoreActions.DashboardAction.eventFlowDetails.sortTable(sortBy));
    },
    [dispatch],
  );

  const onPaginationChange = React.useCallback(
    ({ rowSize, page }) => {
      if (typeof rowSize !== 'undefined') {
        dispatch(StoreActions.DashboardAction.eventFlowDetails.setPageSize(rowSize));
      }
      if (typeof page !== 'undefined') {
        dispatch(StoreActions.DashboardAction.eventFlowDetails.changePage(page));
      }
    },
    [dispatch],
  );

  const onSearchChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(StoreActions.DashboardAction.eventFlowDetails.search(event.target.value));
    },
    [dispatch],
  );

  const onFilterChange = React.useCallback(
    ({ name, value }) => {
      if (name === UNPROCESSED_TABLE_FILTERS_FIELD_NAMES.ProductPensionScheme && value === '') {
        dispatch(
          StoreActions.DashboardAction.eventFlowDetails.removeFilter(
            UNPROCESSED_TABLE_FILTERS_FIELD_NAMES.ProductPensionScheme,
          ),
        );
      } else if (name === EVENT_FLOW_TABLE_FILTERS_FIELD_NAMES.EventType && value === '') {
        dispatch(
          StoreActions.DashboardAction.eventFlowDetails.removeFilter(EVENT_FLOW_TABLE_FILTERS_FIELD_NAMES.EventType),
        );
      } else {
        dispatch(StoreActions.DashboardAction.eventFlowDetails.applyFilter(name, value));
      }
    },
    [EVENT_FLOW_TABLE_FILTERS_FIELD_NAMES.EventType, dispatch],
  );

  const onClickResetButtonHandler = React.useCallback(() => {
    dispatch(
      StoreActions.DashboardAction.eventFlowDetails.removeFilter(
        UNPROCESSED_TABLE_FILTERS_FIELD_NAMES.ProductPensionScheme,
      ),
    );
    dispatch(
      StoreActions.DashboardAction.eventFlowDetails.removeFilter(EVENT_FLOW_TABLE_FILTERS_FIELD_NAMES.EventType),
    );
    dispatch(StoreActions.DashboardAction.eventFlowDetails.removeFilter(UNPROCESSED_TABLE_FILTERS_FIELD_NAMES.Status));
    dispatch(StoreActions.DashboardAction.eventFlowDetails.removeFilter('ibUser'));
    dispatch(StoreActions.DashboardAction.eventFlowDetails.removeFilter('ibEmployer'));
    dispatch(StoreActions.DashboardAction.eventFlowDetails.removeFilter('eventDateAfter'));
    dispatch(StoreActions.DashboardAction.eventFlowDetails.removeFilter('eventDateBefore'));
    dispatch(StoreActions.DashboardAction.eventFlowDetails.search(''));
    dispatch(StoreActions.DashboardAction.eventFlowDetails.selectAllRows([]));
    setKey(Uuid.newV4());
  }, [EVENT_FLOW_TABLE_FILTERS_FIELD_NAMES.EventType, dispatch]);

  const usersAsOptions = React.useMemo(() => {
    if (!users?.data?.length) {
      return [];
    }
    const userMapped = users.data.map((user: User) => ({
      value: user.userId,
      element: user.personName.fullName,
      default: false,
    }));

    return [
      {
        value: '',
        element: intl.formatMessage({ id: TranslationKeys.all }),
        default: true,
      },
      ...userMapped,
    ];
  }, [users, intl]);

  const employersAsOptions = React.useMemo(() => {
    if (!employers?.data?.length) {
      return [];
    }

    const employerMapped = [
      ...(employers.data.map((employer: Employer) => ({
        value: employer.employerId,
        element: employer.employerName,
        default: false,
      })) || []),
      ...(employers.data.flatMap(subEmployer =>
        subEmployer.subEmployers?.map(employer => ({
          value: employer.employerId,
          element: employer.employerName,
          default: false,
        })),
      ) || []),
    ];

    return [
      {
        value: '',
        element: intl.formatMessage({ id: TranslationKeys.all }),
        default: true,
      },
      ...employerMapped,
    ];
  }, [employers, intl]);

  const insurersAsOptions = React.useMemo(() => {
    if (!insurers?.data?.length) {
      return [];
    }
    const userMapped = insurers.data.map((insurer: Insurer) => ({
      value: insurer.insurerId,
      element: `${insurer.insurerName} (${intl.formatMessage({ id: 'products.type.' + insurer.type })})`,
      default: false,
    }));

    return [
      {
        value: '',
        element: intl.formatMessage({ id: TranslationKeys.all }),
        default: true,
      },
      ...userMapped,
    ];
  }, [insurers, intl]);

  const downloadAllEvents = React.useCallback(async () => {
    try {
      if (!isDownloadEventFlowLoading) {
        const response = await downloadEventFlowAsync({
          selectAll: true,
          userId,
          filters: {
            eventFlowIds: [],
            ...filters,
          },
        });

        if (response.status === 200) {
          triggerFileDownload({
            file: response.data,
            fileName: response.headers['x-filename'],
          });
        } else if (response.status === 202) {
          showSuccessSnakeBar({
            method: 'downloadAllEvents',
            message: intl.formatMessage({ id: TranslationKeys.eventflow_tooManyEvents }),
          });
        }
      }
    } catch (error: any) {
      showErrorSnakeBar({ method: 'downloadAllEvents', message: error });
    }
  }, [
    isDownloadEventFlowLoading,
    downloadEventFlowAsync,
    userId,
    filters,
    showSuccessSnakeBar,
    intl,
    showErrorSnakeBar,
  ]);

  const downloadSelectedEvents = React.useCallback(async () => {
    try {
      if (!isDownloadEventFlowLoading) {
        const response = await downloadEventFlowAsync({
          selectAll: false,
          userId,
          filters: {
            eventFlowIds: selectedRows,
            ...filters,
          },
        });

        if (response.status === 200) {
          triggerFileDownload({
            file: response.data,
            fileName: response.headers['x-filename'],
          });
        } else if (response.status === 202) {
          showSuccessSnakeBar({
            method: 'downloadSelectedEvents',
            message: intl.formatMessage({ id: TranslationKeys.eventflow_tooManyEvents }),
          });
        }
        dispatch(StoreActions.DashboardAction.eventFlowDetails.selectAllRows([]));
      }
    } catch (error: any) {
      showErrorSnakeBar({ method: 'downloadSelectedEvents', message: error });
    }
  }, [
    isDownloadEventFlowLoading,
    downloadEventFlowAsync,
    userId,
    selectedRows,
    filters,
    dispatch,
    showSuccessSnakeBar,
    intl,
    showErrorSnakeBar,
  ]);

  return (
    <Box marginTop={1}>
      <EventFlowTableFilters
        filterKey={key}
        filters={filters}
        isLoading={isLoading}
        onSearchChange={onSearchChange}
        onFilterChange={onFilterChange}
        onClickResetButtonHandler={onClickResetButtonHandler}
        searchQuery={searchQuery}
        usersAsOptions={usersAsOptions}
        employersAsOptions={employersAsOptions}
        insurersAsOptions={insurersAsOptions}
        downloadAllEvents={downloadAllEvents}
      />
      <EventFlowTableActions
        isLoading={isLoading}
        usersAsOptions={usersAsOptions}
        refetchEventFlow={refetchEventFlow}
        downloadSelectedEvents={downloadSelectedEvents}
      />
      <EventFlowDetailsTable
        isLoading={isLoading}
        eventFlowDetails={eventFlowDetails?.data || []}
        onPaginationChange={onPaginationChange}
        onSortChange={onSortChange}
        usersAsOptions={usersAsOptions}
        pagination={{
          page,
          pageSize,
          totalCount: eventFlowDetails?.totalCount ?? 0,
        }}
        refetchEventFlow={refetchEventFlow}
      />
    </Box>
  );
};
