import React, { memo } from 'react';
import FormikWrapper from '../FormikWrapper';
import InternalAutocomplete from './InternalAutocomplete';
import type { InputFactoryOnChangeFunc, InputFieldFactoryProps } from '../Factory/InputFieldFactory';
import type { useFormikContext } from 'formik';

export type AutocompleteProps = Pick<InputFieldFactoryProps, 'className' | 'field' | 'hideErrorText'> & {
  onChange?: InputFactoryOnChangeFunc;
  variant?: 'default' | 'slim' | string;
};

const Autocomplete = (props: AutocompleteProps) => {
  return (
    <FormikWrapper>
      {(formik: ReturnType<typeof useFormikContext>) => <InternalAutocomplete formik={formik} {...props} />}
    </FormikWrapper>
  );
};

export default memo(Autocomplete);
