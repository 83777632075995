import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import type { useFormikContext } from 'formik';
import { getIn } from 'formik';
import { Autocomplete as BaseAutocomplete } from '../base';
import { Chip, FormLabel } from '@mui/material';
import MuiTextField from '@mui/material/TextField';
import { isNil } from 'app/utils';
import { useFieldValidator } from '../Factory';
import IntlMessage from '../../Intl/IntlMessage';
import { getActualValue, getDefaultOption, normalizeOptions } from '../Shared/formikFieldHelpers';
import { FieldStyled, SkeletonStyled } from './Autocomplete.styles';
import type { AutocompleteProps } from './Autocomplete';
import type {
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete/Autocomplete';

const unsetValueArray: unknown = [];

type InternalAutocompleteProps = AutocompleteProps & {
  formik: ReturnType<typeof useFormikContext>;
};

const InternalAutocomplete = ({
  onChange,
  field,
  variant = 'default',
  formik,
  className,
}: InternalAutocompleteProps) => {
  const intl = useIntl();
  const {
    label,
    options,
    name,
    value,
    defaultValue,
    header,
    renderInput,
    getOptionLabel,
    isOptionEqualToValue,
    placeholder,
    required = false,
    loading = false,
    disabled = false,
    disableOnChange = false,
    fullWidth = true,
    ...rest
  } = field || {};

  // should be ignored!
  delete rest.schema;
  delete rest.items;

  const { errors, touched, initialValues, setFieldValue, setFieldTouched, isSubmitting, values: formikValues } = formik;
  const formikValue = getIn(formikValues, name);
  const { freeSolo = false, multiple = false } = options || {};
  const unsetValue = multiple ? unsetValueArray : '';

  const normalizedOptions = useMemo(() => {
    return normalizeOptions({ field, loading });
  }, [field, loading]);

  const defaultOption = useMemo(() => {
    return getDefaultOption({
      normalizedOptions,
      defaultValue,
    });
  }, [defaultValue, normalizedOptions]);

  const actualDefaultValue = useMemo(() => {
    const initialValue = getIn(initialValues, name);
    return isNil(initialValue) ? (isNil(defaultOption) ? unsetValue : defaultOption) : initialValue;
  }, [defaultOption, initialValues, name, unsetValue]);

  const actualValue = useMemo(() => {
    return getActualValue({
      normalizedOptions,
      defaultValue: actualDefaultValue,
      formikValue,
      value,
    });
  }, [actualDefaultValue, normalizedOptions, value, formikValue]);

  const actualDisabled = loading || disabled || isSubmitting;
  const [currentValue, setCurrentValue] = useState(actualValue);
  const [fieldDisabled, setFieldDisabled] = useState(actualDisabled);
  const validator = useFieldValidator('autocomplete');
  const size = variant === 'slim' ? 'small' : undefined;

  useEffect(() => {
    setCurrentValue(actualValue);
  }, [actualValue]);

  useEffect(() => {
    setFieldDisabled(actualDisabled);
  }, [actualDisabled]);

  const change = useCallback(
    async (event, value, reason) => {
      if (disableOnChange) {
        setFieldDisabled(true);
      }

      let fieldValue = value;

      if (reason === 'createOption') {
        const newValueArray = fieldValue.concat(event.target.value + '');

        fieldValue = Array.from(
          new Set(
            newValueArray.reduce((acc: Array<string>, item: unknown) => {
              const splits = (item + '')
                .split(/[,;]+/)
                .map(val => val.trim())
                .filter(val => val !== '');

              return acc.concat(splits);
            }, []),
          ),
        );
      }

      let newCurrentValue;
      if (multiple) {
        newCurrentValue = fieldValue.length ? fieldValue : unsetValue;
      } else {
        newCurrentValue = isNil(fieldValue) ? unsetValue : fieldValue;
      }
      setCurrentValue(newCurrentValue);

      // needs to be performed on this order!
      setFieldTouched(name, true, false);

      const newFieldValue = (freeSolo || multiple ? fieldValue : fieldValue?.value) ?? unsetValue;
      await setFieldValue(name, newFieldValue, true);

      onChange && onChange({ event, name, value: newFieldValue, setFieldValue });
    },
    [disableOnChange, multiple, setFieldTouched, name, freeSolo, setFieldValue, onChange, unsetValue],
  );

  const optionLabel = useCallback(
    option => {
      if (getOptionLabel) {
        return getOptionLabel(option);
      }

      if (loading) {
        return '';
      }

      const valueToDisplay = option?.element ?? (option?.value || '');
      return intl.messages[valueToDisplay] ?? valueToDisplay;
    },
    [getOptionLabel, intl.messages, loading],
  );

  const optionSelected = useCallback(
    (item, current) => {
      if (isOptionEqualToValue) {
        return isOptionEqualToValue(item, current);
      }
      if (current === unsetValue) {
        return true; // this is merely for the loading scenario where at least an item must match
      }
      return item.value === current?.value || item.value === current;
    },
    [isOptionEqualToValue, unsetValue],
  );

  const render = useCallback(
    (params, errors, touched) => {
      if (renderInput) {
        return renderInput(params, errors, touched);
      }

      const fieldParams = { ...params };
      if (loading) {
        fieldParams.InputProps = {
          ...fieldParams.InputProps,
          startAdornment: <>{loading ? <SkeletonStyled /> : null}</>,
        };
      }

      if (!loading && placeholder) {
        fieldParams.placeholder = placeholder;
      }

      const helperText = loading ? undefined : getIn(touched, name) && getIn(errors, name);
      const error = loading ? false : Boolean(helperText);

      return (
        <MuiTextField {...fieldParams} error={error} helperText={helperText} name={name} label={label} size={size} />
      );
    },
    [label, loading, name, placeholder, renderInput, size],
  );

  const validate = useCallback(
    value => {
      return validator(value, field);
    },
    [field, validator],
  );

  return (
    <div className={className}>
      {header && (
        <FormLabel>
          <IntlMessage value={header} /> {required ? ' *' : ''}
        </FormLabel>
      )}
      <FieldStyled
        slim={variant === 'slim'}
        disabled={fieldDisabled}
        component={BaseAutocomplete}
        name={name}
        label={label}
        value={currentValue}
        onChange={change}
        options={normalizedOptions}
        renderTags={(tags: Array<any>, getTagProps: AutocompleteRenderGetTagProps) =>
          tags.map((tag, index) => (
            <Chip
              color="primary"
              size="small"
              label={tag?.element ?? tag}
              {...getTagProps({ index })}
              key={tag?.value ?? tag}
            />
          ))
        }
        renderInput={(params: AutocompleteRenderInputParams) => render(params, errors, touched)}
        getOptionLabel={optionLabel}
        isOptionEqualToValue={freeSolo ? undefined : optionSelected}
        fullWidth={fullWidth}
        required={required}
        loading={loading}
        size={size}
        validate={validate}
        freeSolo={freeSolo}
        multiple={multiple}
        {...rest}
      />
    </div>
  );
};

export default InternalAutocomplete;
