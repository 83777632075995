import type { Employer } from './employer.types';
import type { Owner } from './owner.types';
import type { Connection } from './connection.types';

export const UnprocessedEventConnectionEventNames = [
  'new-employee',
  'contract-updated',
  'employee-address-updated',
  'employee-information-updated',
  'employee-part-time-percentage-updated',
  'employee-partner-updated',
  'gross-wage-updated',
  'employee-left-company',
  'new-periodic-wage',
  're-employed',
] as const;

export type UnprocessedEventConnectionEventType = {
  eventName: (typeof UnprocessedEventConnectionEventNames)[number];
  total: number;
};

export type UnprocessedEvent = {
  employerId: Employer['employerId'];
  employerName: Employer['employerName'];
  ownerId: Owner['ownerId'];
  eventTypeTotals: Record<UnprocessedEventConnectionEventType['eventName'], number>;
  total: number;
  connections: Array<{
    employerId: Employer['employerId'];
    connectionId: Connection['connectionId'];
    connectionType: Connection['connectionType'];
    connectionName: Connection['connectionName'];
    latestRun?: string | null;
    nextRun?: string | null;
    eventTypes: Record<UnprocessedEventConnectionEventType['eventName'], number>;
    total: number;
  }>;
};

export type UnprocessedBatch = {
  batchId: string;
  employerId: Employer['employerId'];
  ownerId: Owner['ownerId'];
  subOwnerId: Owner['ownerId'];
  connectionId: Connection['connectionId'];
  mongoId: string;
  connectionName: Connection['connectionName'];
  connectionType: Connection['connectionType'];
  triggeredAt: string;
  eventsCount: number;
  errorsCount: number;
  mutationsCount: number;
  state: string;
};

export type MissingDetail = {
  employerId: Employer['employerId'];
  connectionId: Connection['connectionId'];
  connectionName: Connection['connectionName'];
  connectionType: Connection['connectionType'];
  latestRun: string;
  createdAt: string;
};

export type EventFlow = {
  '@id': string;
  mongoId: string;
  userId: string;
  userName: string;
  eventId: string;
  ownerId: string;
  connectionId: string;
  employerId: string;
  employerName: string;
  employeeId: string;
  employeeBsn: string;
  employeeName: string;
  insurerId: string;
  insurerName: string;
  productId: string;
  productName: string;
  comment: string;
  deletedAt: string;
  createdAt: string;
  updatedAt: string;
  eventType: string;
  eventState: string;
  eventDate: string;
  pensionScheme: string;
  status: string;
  logs: Array<{
    userId: string;
    userName: string;
    status: string;
    comment: string;
    createdAt: string;
  }>;
};

export enum EventFlowStatus {
  TODO = 'to-do',
  IN_PROGRESS = 'in-progress',
  IN_PROGRESS_SENT = 'in-progress.sent',
  IN_PROGRESS_REJECTED = 'in-progress.rejected',
  IN_PROGRESS_CHECKED = 'in-progress.checked',
  IN_PROGRESS_CHECKED_EMPLOYER = 'in-progress.checked-employer',
  COMPLETED_CHECKED = 'completed.checked',
  COMPLETED_DONE = 'completed.done',
}

export type ConnectionErrorsDetail = {
  employerId: Employer['employerId'];
  connectionId: Connection['connectionId'];
  connectionName: Connection['connectionName'];
  moduleType: string;
  packageType: string;
  startedAt: string;
};

export type BlackAndWhiteListDetail = {
  connectionId: Connection['connectionId'];
  connectionName: Connection['connectionName'];
  packages: Connection['packages'];
  employerId: Employer['employerId'];
  listType: string;
};
